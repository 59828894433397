//import uuid from 'uuid/v4';

export const mapReducer = (state, action) => {
  switch (action.type) {
    case 'ADD_BOOK':
      return [...state, {
        title: action.book.title,
        author: action.book.author,
        //id: uuid()
      }
      ]
    case 'UPDATE_CAMPUS':
      const campusId = action.payload.id || state.campus.id;
      return {
        ...state,
        campus: {
          ...state.campus,
          key: action.payload.campusKey,
          id: campusId,
          name: action.payload.name || state.campus.name
        }
      }
    case 'MAP_LOADED': 
      return {
        ...state,
        mapLoaded: action.payload.mapLoaded,
        monashMap: action.payload.monashMap || false
      }    
  
    case 'UPDATE_DIRECTION': 
      return {
        ...state,
        directions: {
          ...state.directions,
          ...action.payload
        }
      }
    case 'SWAP_DIRECTION':
      return {
        ...state,
        directions: {
          ...state.directions,
          to: state.directions.from,
          from: state.directions.to
        }
      }
    default:
      return state;
  }
}