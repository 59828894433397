import React, { createContext, useReducer } from 'react';
import { mapReducer } from '../reducers/mapReducer';

export const MapContext = createContext();


const initialState = ({
  directions: {
    from: null,
    to: null,
    mode: 'PEDESTRIAN',
    avoidStairs: false,
    poi: null,
    pathMetrics: null
  },
  mapLoaded: false,
  monashMap: null,
  campus: {       //selected campus from map
    key: null,    //campus name
    id: null,
    name: "",
    categories: {}
  }  
});



const MapContextProvider = (props) => {
  const [map, dispatch] = useReducer(mapReducer, initialState);
  return (
    <MapContext.Provider value={{ map, dispatch }}>
      {props.children}
    </MapContext.Provider>
  );
}
 
export default MapContextProvider;