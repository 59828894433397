import { toFriendlyTime } from './datetime'
import geoData from './../data/geojson.json'

//const $ = require('jquery')

const CAMPUS_DETAILS = {
    
    clayton: {
        name: "Clayton",
        lng: 145.136514,
        lat: -37.910881,
        id: 159
    },
    caulfield: {
        name: "Caulfield",
        lng: 145.044557,
        lat: -37.876837,
        id: 410
    },
    parkville: {
        name: "Parkville",
        lng: 144.958812,
        lat: -37.783167,
        id:412
    },
    peninsula: {
        name: "Peninsula",
        lng: 145.135897,
        lat: -38.152593,
        id:411
    }

}

function switchCampus(campusName) {
    if (campusName && campusName !== "") {
        const { lng, lat } = CAMPUS_DETAILS[campusName];

        window.map.flyTo({
            center: {
                lng,
                lat
            },
            zoom: 16
        });
    }
}

const placePoiMarker = (poi) => {

    // Get a center point for the POI, because the data can return a polygon instead of just a point sometimes
    const lngLat = window.Mazemap.Util.getPoiLngLat(poi);

    window.map.muStore.marker = new window.Mazemap.MazeMarker({
        color: '#ff00cc',
        innerCircle: true,
        innerCircleColor: '#FFF',
        size: 34,
        innerCircleScale: 0.5,
        zLevel: poi.properties.zLevel
    })
    .setLngLat(lngLat)
    .addTo(window.map)

    // If we have a polygon, use the default 'highlight' function to draw a marked outline around the POI.
    if (poi.geometry.type === "Polygon") {
        window.map.muStore.highlighter.highlight(poi);
    }
    window.map.flyTo({ center: lngLat, zoom: 19, speed: 1, zLevel: poi.properties.zLevel });

    const popup = new window.Mazemap.Popup({ closeOnClick: true, offset: [0, -27] });
    //popup.setHTML(`<h3>${poi.properties.buildingName} - ${poi.properties.title}</h3><p style="max-width: 160px;">${poi.properties.identifier}</p><button onclick="startDirections(this,${lngLat.lng}, ${lngLat.lat})" class="btn btn-direction-mm ">Directions from my Location</button> <button data-role="start">Start from Here</button> <button>End Here</button>`)
    
    // const button = $('<button />')
    // button.html("test")
    // button.on('click', () => {
    //     alert('me')
    // })
    //alert(button.get(0).outerHTML)
    //popup.setHTML(button.get(0).outerHTML)
    // const popupElem = popup.getElement();
    // $(popupElem).find('button[data-role=start]').on('click', () => {
    //     alert('me')
    // })

    window.map.muStore.marker
    .setPopup(popup);

}

const placeMarker = (lngLat) => {

    window.map.muStore.marker = new window.Mazemap.MazeMarker({
        color: '#00ffcc',
        innerCircle: true,
        innerCircleColor: '#FFF',
        size: 34,
        innerCircleScale: 0.5,
        //zLevel,
    })   
    .setLngLat(lngLat)
    .addTo(window.map)

    window.map.flyTo({ center: lngLat, zoom: 19, speed: 1 });

    window.map.muStore.marker.setPopup(new window.Mazemap.Popup({ closeOnClick: true, offset: [0, -27] })
    .setHTML(`<h3>Geocode</h3><p style="max-width: 160px;">Lat: ${lngLat.lat}, Lng: ${lngLat.lng}</p><button onclick="startDirections(this,${lngLat.lng}, ${lngLat.lat})" class="btn btn-direction-mm ">Directions</button>`));

}

const clearMarker = (monashMap) => {
    
    if (monashMap.muStore && monashMap.muStore.marker) {
        monashMap.muStore.marker.remove();
    }
    if (monashMap && monashMap.muStore && monashMap.muStore.highlighter) {
        monashMap.muStore.highlighter.clear();
    }
}

const clearCategoryMarkers = (monashMap) => {
    
    if (monashMap.muStore && monashMap.muStore.markers) {
        for(var i = 0; i < monashMap.muStore.markers.length; i++){
            monashMap.muStore.markers[i].remove();
        }
    }
    if (monashMap && monashMap.muStore && monashMap.muStore.highlighter) {
        monashMap.muStore.highlighter.clear();
    }
}

const placePoiCategoryMarkers = (pois) => {

    var poi;
    window.map.muStore.markers = window.map.muStore.markers || [];
    for(var i = 0; i < pois.features.length; i++){
        poi = pois.features[i];

        // Get a center point for the POI, because the data can return a polygon instead of just a point sometimes
        const lngLat = window.Mazemap.Util.getPoiLngLat(poi);

        let marker = new window.Mazemap.MazeMarker({
            color: 'rgb(0, 105, 206)',
            innerCircle: true,
            innerCircleColor: '#FFF',
            size: 30,
            shape: 'circle',
            innerCircleScale: 0.4,
            zLevel: poi.properties.zLevel
        })
        .setLngLat(lngLat)
        .addTo(window.map);

        window.map.muStore.markers.push(marker)
        

    }

  
    if(pois.features.length > 0) {
        var bbox = window.Mazemap.Util.Turf.bbox(pois);
        var bounds = new window.Mazemap.mapboxgl.LngLatBounds(bbox);
        window.map.fitBoundsRotated(bounds, {padding: 100});
    }


 
}

const getCampusCategories = (campusId) => {
    if (!(campusId && campusId > 0)) {
        return new Promise(function(resolve,reject) {
            resolve([])
        })
    }

    const get_data = new Promise(function(resolve, reject) {
        // resolve({data: "test"})
        // if (Math.random() * 100 < 90) {
        //     reject(new Error('The promise was rejected by using reject function.'));
        //   }
        //   throw new Error('The promise was rejected by throwing an error');
 
        window.Mazemap.Data.getCampus(campusId).then(campus => {
            const categoryIds = campus.properties.poiTypesSelectableInApp;
            if (categoryIds.length < 1) {
                return resolve([])
            }
   
            const allPromises = categoryIds.map((id) => {
                return new Promise((resolve, reject) => {
                    window.Mazemap.Data.getTypeById(id).then(type => {
                        // console.log(type); // Raw data about the POI category/type
                        resolve(type)
                    }).catch((e) => reject(new Error(e.message)));                  

                });
            })

            Promise.all(allPromises).then(values => {
                resolve(values)
            })
            .catch((e) => reject(new Error(e.message)));

            
        })
        .catch((e) => reject(new Error(e.message)));
        
      });
      
      //return promise 
      return get_data;


  };


const onMapPOIClick = (e) => {

    /*
    const lngLat = e.lngLat;
    const clickData = Mazemap.Util.getMapClickData(map, lngLat);
    console.log('Got click data:', clickData);
    */
    // Clear existing, if any
    clearMarker(window.map);
   
    var lngLat = e.lngLat;
    var zLevel = window.map.zLevel;

    // Fetching via Data API
    window.Mazemap.Data.getPoiAt(lngLat, zLevel).then( poi => {
        //printPoiData(poi);
        placePoiMarker(poi);

    }).catch( function(){ return false; } );
}

function setRoute( start, dest, monashMap, routeOptions, cb  ){
    monashMap.muStore.routeController.clear(); // Clear existing route, if any

            //avoidStairs: "false" or "true"
            //mode: "PEDESTRIAN" or "BICYCLE" or "DRIVE" or "TRANSIT"
            //const options = {avoidStairs: false, mode: "PEDESTRIAN"}
    
    window.Mazemap.Data.getRouteJSON(start, dest, routeOptions)
    .then(function(geojson){
        console.log('@ getRouteJSON geojson', geojson);
        monashMap.muStore.routeController.setPath(geojson);
        var bounds = window.Mazemap.Util.Turf.bbox(geojson);
        monashMap.fitBounds( bounds, {padding: 100} );
        
        if (typeof cb ==='function') {
            cb(geojson)
        }
        
    });
}

function printRouteData(route, mode){
    //var routeStr = JSON.stringify(route, null, 2); // spacing level = 2
    const seconds = route.properties.pathMetrics.durationEstimateSeconds
    var html = ''; //durationEstimateSeconds
    for (let index = 0; index < route.features.length; index++) {
        const f = route.features[index];
        const geoType = f.geometry.type;
        
        
        if (geoType === "LineString" ) {
            // if route point is for stairs we dont need to show
            // follow use.mazemap.com exmaple.

                if (typeof f.properties.routepoint === "undefined") {
                            /*
                            ${f.properties.travelMode} - 
                            ${f.properties.travelType} : 
                            */
                            html += `
                        <li title="${f.properties.routepoint}" data-routepoint="${f.properties.routepoint}" >
                            ${renderToFriendlyTravelType(f.properties.travelType)}
                            <span>${Math.round(f.properties.m)} meters</span>
                        </li>`;
                }

        }else if(geoType === "Point") {
            html += `
            <li>
                ${renderToFriendlyTravelType(f.properties.routepoint)}
            </li>
        `;
        }
    }

    return `<b>${mode}: ${toFriendlyTime(seconds)}</b><hr/>${html}`;

    // console.log(route); // Can also look in your console to see the object there
}

function renderToFriendlyTravelType(name){
    switch (name) {
        case  "FOOT":
            return "Walk"

        case "STAIR":
            return "Stairs"

        case "BUILDING_ENTER":
            return "Enter Building"
            
        case "BUILDING_EXIT":
            return "Exit Building"
            
        default:
            return name;
    }
}

function placeDirectionsMarker(direction = 'from', lngLat) {
    window.map.muStore.directionInfo = window.map.muStore.directionInfo || {}

    const marker = new window.Mazemap.MazeMarker({
        glyphColor:'#FFF',
        glyphSize: 20,
        glyph: direction //Could even be an emoji, such as '🖨'
    })
    .setLngLat(lngLat)
    .addTo(window.map);

    if (window.map.muStore.directionInfo[direction]) {
        window.map.muStore.directionInfo[direction].remove();
    }
    window.map.muStore.directionInfo = {
        ...window.map.muStore.directionInfo,
        [direction]: marker
    }
}

function drawWellLitRoute () {
  
    window.map.addSource('route', {
      'type': 'geojson',
      'data': {
        'type': 'Feature',
        'properties': {},
        'geometry': {
          'type': 'LineString',
          'coordinates': geoData
        }
      }
    });

    window.map.addLayer({
      'id': 'route',
      'type': 'line',
      'source': 'route',
      'layout': {
        'line-join': 'round',
        'line-cap': 'round'
      },
      'paint': {
        'line-color': '#f3f30b',
        'line-width': 5,
        //'line-gap-width':10,
        'line-dasharray': [5, 5]
      }
    });


  }

const updateSearchController = (campusId) => {
    setTimeout(()=>window.map.muStore.searchController.options.campusid= campusId, 10 )
}

const nearestToCampus = (lat,lng) => {

    let campusKey = null;
    let distance = -1;

    Object.keys(CAMPUS_DETAILS).forEach(function (key) {
        let campus = CAMPUS_DETAILS[key]
        let dist = distanceBetweenLngLat( lat, lng, campus.lat, campus.lng);
        if (distance === -1 || dist < distance) {
            campusKey = key
            distance = dist
        }
    })

    return {
        ...CAMPUS_DETAILS[campusKey],
        distanceAwayKM: distance
    }

}


function distanceBetweenLngLat(lat1, lon1, lat2, lon2, unit = 'K') {
    
	if ((lat1 === lat2) && (lon1 === lon2)) {
		return 0;
	}
	else {
		var radlat1 = Math.PI * lat1/180;
		var radlat2 = Math.PI * lat2/180;
		var theta = lon1-lon2;
		var radtheta = Math.PI * theta/180;
		var dist = Math.sin(radlat1) * Math.sin(radlat2) + Math.cos(radlat1) * Math.cos(radlat2) * Math.cos(radtheta);
		if (dist > 1) {
			dist = 1;
		}
		dist = Math.acos(dist);
		dist = dist * 180/Math.PI;
		dist = dist * 60 * 1.1515; 
		if (unit==="K") { dist = dist * 1.609344 }
		if (unit==="N") { dist = dist * 0.8684 }
		return dist;
	}
}


const getCampusById = (id) => {
    const key = Object.keys(CAMPUS_DETAILS).filter(function (key) {
        const campus = CAMPUS_DETAILS[key]
        return campus.id === id
    })
    return CAMPUS_DETAILS[key]
}

/*
    return object of items at center of map
    ie: { campusIds: [159]}
*/
const getItemsAtTheCenterOfTheMap = (map) => {
    var centerLngLat = map.getCenter();
    var itemsAtTheCenterOfTheMap = window.Mazemap.Util.getMapClickData(map, centerLngLat);
    return itemsAtTheCenterOfTheMap
    // {
    //     campusIds: [33],
    //     buildingIds: [651],
    //     floorIds: [5487],
    // }
}

const hasAllRoutingData = (data) => {
    // can contain poiId or just { lat / lng }
    let hasFrom  =  (data && data.from && data.from.lat) || (data && data.from && data.from.properties && data.from.properties.poiId)
    let hasTo  =  (data && data.to && data.to.lat) || (data && data.to && data.to.properties && data.to.properties.poiId) 

    return hasFrom && hasTo
  }

const mapWorker = {
    getItemsAtTheCenterOfTheMap,
    getCampusById,
    nearestToCampus,
    updateSearchController,
    drawWellLitRoute,
    placeDirectionsMarker,
    printRouteData,
    setRoute,
    onMapPOIClick,
    placePoiCategoryMarkers,
    clearCategoryMarkers,
    getCampusCategories,
    clearMarker,
    placePoiMarker,
    switchCampus,
    placeMarker,
    CAMPUS_DETAILS,
    hasAllRoutingData
}



export default mapWorker