
import s from './Directions.module.css'
import bike from './icons/bicycle.svg'
import walk from './icons/pedestrian.svg'
import car from './icons/car.svg'
import pt from './icons/pt.svg'

export default function Directions({
        children, 
        directions, 
        goHandler, 
        updateDirectionHandler,
        backButtonHandler,
        swapDirectionHandler
    }) {

    const dest1 = (directions.from ? directions?.from?.properties?.identifier || directions.from.lng : "") 
    const dest2 = (directions.to ? directions?.to?.properties?.identifier || directions.to.lng : "") 

    function updateDirection(key, value) {
        updateDirectionHandler({
            ...directions,
            [key]: value
        })
    }

    return (
        <div>
            <div className={s.container}>
            <div className={s.row}>

                <div>
                    <button onClick={(e)=> backButtonHandler()} className={"backArrow"}>&larr;</button> <input type="text" defaultValue={dest1}  />
                </div>
                <div>
                <button className={"swapBtn"} onClick={(e)=> swapDirectionHandler()}>
                    <span>&#8593;</span>
                    <span>&#8595;</span>
                </button> <input type="text" defaultValue={dest2} />
                </div>

            </div>
            <div className={s.row}>
                <label htmlFor="avoidStairs"> Avoid Stairs
                    <input 
                        type="checkbox" name="" id="avoidStairs" value={directions.avoidStairs || false} 
                        onChange={(e) => updateDirection('avoidStairs',(!directions.avoidStairs ))} />
                </label>

                <ul className={s.hz}>
                    <li><input type="radio" name="mode" id="modeWalk" value="PEDESTRIAN" checked={directions.mode === 'PEDESTRIAN'} onChange={(e) => updateDirection('mode', 'PEDESTRIAN')} /><label htmlFor="modeWalk"><img src={walk} alt="walk" /></label></li>
                    <li><input type="radio" name="mode" id="modeBike" value="BICYCLE" checked={directions.mode === 'BICYCLE'} onChange={(e) => updateDirection('mode', 'BICYCLE')} /><label htmlFor="modeBike"><img src={bike} alt="bike" /></label></li>
                    <li><input type="radio" name="mode" id="modeCar" value="DRIVE" checked={directions.mode === 'DRIVE'} onChange={(e) => updateDirection('mode', 'DRIVE')}  /><label htmlFor="modeCar"><img src={car} alt="car" /></label></li>
                    <li><input type="radio" name="mode" id="modeTransit" value="TRANSIT" checked={directions.mode === 'TRANSIT'}  onChange={(e) => updateDirection('mode', 'TRANSIT')} /><label htmlFor="modeTransit"><img src={pt} alt="public transport" /></label></li>
                    <li><button onClick={goHandler}>GO</button></li>
                </ul>
            </div>
            </div>
        </div>
    )



}