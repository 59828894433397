
import s from './PanelBottom.module.css'
export default function PanelBottom({ isOpen, onRequestClose, children, css }) {

    return <div className={`bottompanel ${isOpen ? 'open' : ''} ${css || ''}`}>
        <div className={s.c}>
            <div className="header">
                <button  onClick={onRequestClose}>X</button>
            </div>
            {children} 
        </div>
    </div>
}

