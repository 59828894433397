export const toFriendlyTime = (seconds) => {
    // we can use Mazemap.Util.minutesToString(123,true) or Mazemap.Util.metersToString(1231232)
    const h = seconds / 3600
    const m = (h - Math.floor(h)) * 60
    //const s = Math.round((m - Math.floor(m)) * 60)
    if (h > 1) {
        return `${Math.floor(h)}:${Math.floor(m)}`
    }
    return `${Math.floor(m)} min`
    //return `${Math.floor(h)}:${Math.floor(m)}:${s}`; //returns full hh:mm:ss
}


