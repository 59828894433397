import React from "react";

const Header = () => {
    return (

        <React.Fragment>

        <header id="hdr" className="hdr" role="banner">
          <div className="hdr-utils">
            <nav id="SiteNav" className="hdr-utils__site-nav" aria-label="Site">
              <ul className="hdr-utils__nav">
                <li className="hdr-utils__nav-item">
                  <a
                    href="https://www.monash.edu"
                    className="hdr-utils__nav-button"
                    data-click-event="monash.navigation.link"
                    data-type="link"
                    data-section="global utility navigation"
                    data-title="Home"
                  >
                    <span className="hdr-utils__icon hdr-utils__icon--home">
                      <span className="sr-only">Home</span>
                      <svg
                        className="icon"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 16 14"
                      >
                        <title>Home icon</title>
                        <use
                          xmlnsXlink="/assets/images/icon-spritesheet.svg#home"
                        ></use>
                      </svg>
                    </span>
                  </a>
                </li>
              </ul>
            </nav>
            <nav
              id="QuickLinks"
              className="hdr-utils__quick-links-nav"
              aria-label="Quick Links"
            >
              <ul className="hdr-utils__nav">
                <li className="hdr-utils__nav-item graphite-legal">
                  <a
                    className="hdr-utils__nav-button graphite-p-s"
                    href="https://www.monashcollege.edu.au/study-areas"
                    data-click-event="monash.navigation.link"
                    data-type="link"
                    data-section="global utility navigation"
                    data-title="Study areas"
                  >
                    Study areas
                  </a>
                </li>
                <li className="hdr-utils__nav-item graphite-legal">
                  <a
                    className="hdr-utils__nav-button graphite-p-s"
                    href="https://www.monashcollege.edu.au/courses"
                    data-click-event="monash.navigation.link"
                    data-type="link"
                    data-section="global utility navigation"
                    data-title="Courses"
                  >
                    Courses
                  </a>
                </li>
                <li className="hdr-utils__nav-item graphite-legal">
                  <a
                    className="hdr-utils__nav-button graphite-p-s"
                    href="https://www.monashcollege.edu.au/life-at-monash-college/our-campus"
                    data-click-event="monash.navigation.link"
                    data-type="link"
                    data-section="global utility navigation"
                    data-title="Campus"
                  >
                    Campus
                  </a>
                </li>
              </ul>
            </nav>
            <nav id="FeaturedNav" className="hdr-utils__featured-nav" aria-label="Featured">
              <ul className="hdr-utils__nav">
                <li className="hdr-utils__nav-item">
                  <div
                    id="StaffSelectorDropdown"
                    className="
                      select-dropdown util-selector util-selector--reverse util-selector--featured"
                    data-select-dropdown="container"
                  >
                    <label
                      className="util-selector__label graphite-label-m"
                      htmlFor="featuredStaffSelector"
                      data-select-dropdown="label"
                      aria-label="Staff Menu"
                    >
                      <span>Staff</span>
                      <span className="util-selector__icon" aria-hidden="true">
                        <svg className="icon">
                          <use xmlnsXlink="/assets/images/icon-spritesheet.svg#staff"></use>
                        </svg>
                      </span>
                    </label>
                    <select
                      id="featuredStaffSelector"
                      className="selectric-disabled graphite-p-m"
                      data-select-dropdown="select"
                      defaultValue=""
                    >
                      <option value="" disabled>Staff</option>
                      <option
                        value="https://www.monashcollege.edu.au/media/links/mymonash"
                      >
                        my.monash
                      </option>
                      <option
                        value="https://www.monashcollege.edu.au/media/links/sharepoint"
                      >
                        Intranet
                      </option>
                      <option
                        value="https://www.monashcollege.edu.au/media/links/workplace"
                      >
                        Workplace
                      </option>
                    </select>
                  </div>
                </li>
                <li className="hdr-utils__nav-item">
                  <div
                    id="StudentsSelectorDropdown"
                    className="
                      select-dropdown
                      util-selector util-selector--reverse util-selector--featured
                    "
                    data-select-dropdown="container"
                  >
                    <label
                      className="util-selector__label graphite-label-m"
                      htmlFor="featuredStudentsSelector"
                      data-select-dropdown="label"
                      aria-label="Students Menu"
                    >
                      <span>Students</span>
                      <span className="util-selector__icon" aria-hidden="true">
                        <svg className="icon">
                          <use
                            xmlnsXlink="/assets/images/icon-spritesheet.svg#students"
                          ></use>
                        </svg>
                      </span>
                    </label>
                    <select
                      id="featuredStudentsSelector"
                      className="selectric-disabled graphite-p-m"
                      data-select-dropdown="select"
                      defaultValue=""
                    >
                      <option value="" disabled>Students</option>
                      <option
                        value="https://www.monashcollege.edu.au/media/links/mymonash"
                      >
                        my.monash
                      </option>
                      <option value="https://www.monashcollege.edu.au/current-students">
                        Student info
                      </option>
                      <option
                        value="https://www.monashcollege.edu.au/media/links/moodle"
                      >
                        Moodle
                      </option>
                      <option value="https://www.monashprofessional.edu.au">
                        Professional Pathways
                      </option>
                    </select>
                  </div>
                </li>
              </ul>
            </nav>
          </div>
          <div className="hdr-main">
            <a
              className="hdr-main__brand"
              href="https://www.monashcollege.edu.au"
              data-click-event="monash.navigation.link"
              data-type="link"
              data-section="Header"
              data-title="Monash University 60 Years of Making Change"
            >
              <img
                className="hdr-main__brand-img"
                src="https://www.monash.edu/__data/assets/file/0015/2521311/60YearsStamp_MonashLogo_MONO_WEB.svg"
                alt="Monash College"
                title="Monash College"
              />
            </a>
            <button
              className="hdr-main__drawer-trigger"
              data-nav-drawer="open"
              aria-controls="nav-drawer"
            >
              <span className="sr-only">Menu</span>
              <span className="hdr-main__drawer-trigger-icon" aria-hidden="true">
                <svg role="img">
                  <use
                    xmlnsXlink="/assets/images/icon-spritesheet.svg#menu"
                  ></use>
                </svg>
              </span>
            </button>
            <div className="hdr-main__main">
              <div className="hdr-main__wrapper" data-for="nav">
                <nav id="main-nav-desktop" className="hdr-main__nav" data-hdr-main-nav>
                  <section className="hdr-main__nav-section" data-nav-section>
                    <a
                      className="hdr-main__nav-link graphite-nav-menu-link"
                      href="https://www.monashcollege.edu.au/study-areas"
                      target="_self"
                      data-nav-link
                      data-click-event="monash.navigation.link"
                      data-type="link"
                      data-section="Header"
                      data-title="Study areas"
                    >
                      Study areas
                    </a>
                  </section>
                  <section className="hdr-main__nav-section" data-nav-section>
                    <a
                      className="hdr-main__nav-link graphite-nav-menu-link"
                      href="https://www.monashcollege.edu.au/courses"
                      target="_self"
                      data-nav-link
                      data-click-event="monash.navigation.link"
                      data-type="link"
                      data-section="Header"
                      data-title="Courses"
                    >
                      Courses
                    </a>
                  </section>
                  <section className="hdr-main__nav-section" data-nav-section>
                    <a
                      className="hdr-main__nav-link graphite-nav-menu-link"
                      href="https://www.monashcollege.edu.au/life-at-monash-college"
                      target="_self"
                      data-nav-link
                      data-click-event="monash.navigation.link"
                      data-type="link"
                      data-section="Header"
                      data-title="College Life"
                    >
                      College Life
                    </a>
                  </section>
                  <section className="hdr-main__nav-section" data-nav-section>
                    <a
                      className="hdr-main__nav-link graphite-nav-menu-link"
                      href="https://www.monashcollege.edu.au/how-to-apply"
                      target="_self"
                      data-nav-link
                      data-click-event="monash.navigation.link"
                      data-type="link"
                      data-section="Header"
                      data-title="How to apply"
                    >
                      How to apply
                    </a>
                  </section>
                  <section className="hdr-main__nav-section" data-nav-section>
                    <a
                      className="hdr-main__nav-link graphite-nav-menu-link"
                      href="https://www.monashcollege.edu.au/information-for"
                      target="_self"
                      data-nav-link
                      data-click-event="monash.navigation.link"
                      data-type="link"
                      data-section="Header"
                      data-title="Information for"
                    >
                      Information for
                    </a>
                  </section>
                  <section className="hdr-main__nav-section" data-nav-section>
                    <a
                      className="hdr-main__nav-link graphite-nav-menu-link"
                      href="https://www.monashcollege.edu.au/current-students"
                      target="_self"
                      data-nav-link
                      data-click-event="monash.navigation.link"
                      data-type="link"
                      data-section="Header"
                      data-title="Current students"
                    >
                      Current students
                    </a>
                  </section>
                  <section className="hdr-main__nav-section" data-nav-section>
                    <a
                      className="hdr-main__nav-link graphite-nav-menu-link"
                      href="https://www.monashcollege.edu.au/about-us"
                      target="_self"
                      data-nav-link
                      data-click-event="monash.navigation.link"
                      data-type="link"
                      data-section="Header"
                      data-title="About us"
                    >
                      About us
                    </a>
                  </section>
                  <section className="hdr-main__nav-section" data-nav-section>
                    <a
                      className="hdr-main__nav-link graphite-nav-menu-link"
                      href="https://www.monashcollege.edu.au/contact-us"
                      target="_self"
                      data-nav-link
                      data-click-event="monash.navigation.link"
                      data-type="link"
                      data-section="Header"
                      data-title="Contact us"
                    >
                      Contact us
                    </a>
                  </section>
                  <section
                    className="hdr-main__nav-section hdr-main__nav-section--more"
                    data-nav-section="more"
                    data-trigger-event="click"
                  >
                    <a
                      className="hdr-main__nav-link graphite-nav-menu-link"
                      href="#"
                      data-nav-link
                    >
                      More
                      <span className="hdr-main__nav-link-icon" aria-hidden="true">
                        <svg role="img">
                          <use
                            xmlnsXlink="/assets/images/icon-spritesheet.svg#more"
                          ></use>
                        </svg>
                      </span>
                    </a>
                    <input
                      className="hdr-main__nav-trigger"
                      type="radio"
                      name="hdrMainNavSubmenu"
                      aria-label="Expand submenu for 'More'"
                      data-nav-trigger
                    />
                    <span className="hdr-main__nav-trigger-icon" aria-hidden="true"></span>
                    <div className="hdr-main__nav-submenu">
                      <div className="hdr-main__nav-submenu-wrapper">
                        <ul className="hdr-main__nav-submenu-list" data-subnav></ul>
                      </div>
                    </div>
                  </section>
                </nav>
              </div>
            </div>
            <div className="hdr-main__aside"></div>
          </div>
        </header>

<section
  id="nav-drawer"
  className="nav-drawer"
  role="navigation"
  data-nav-drawer="container"
  aria-hidden="true"
  tabIndex="-1"
>
  <div className="nav-drawer__top-bar">
    <ul className="nav-drawer__top-bar-nav">
      <li className="nav-drawer__top-bar-nav-item">
        <a
          className="graphite-p-s"
          href="https://www.monashcollege.edu.au"
          data-click-event="monash.navigation.link"
          data-type="link"
          data-section="global utility navigation"
          data-title="Home"
        >
          <span className="sr-only">Home</span>
          <svg className="icon nav-drawer__icon nav-drawer__icon--home">
            <use
              xmlnsXlink="/assets/images/icon-spritesheet.svg#home"
            ></use>
          </svg>
        </a>
      </li>
      <li className="nav-drawer__top-bar-nav-item">
        <a
          className="graphite-p-s"
          href="https://www.monashcollege.edu.au/study-areas"
          data-click-event="monash.navigation.link"
          data-type="link"
          data-section="global utility navigation"
          data-title="Study areas"
        >
          Study areas
        </a>
      </li>
      <li className="nav-drawer__top-bar-nav-item">
        <a
          className="graphite-p-s"
          href="https://www.monashcollege.edu.au/courses"
          data-click-event="monash.navigation.link"
          data-type="link"
          data-section="global utility navigation"
          data-title="Courses"
        >
          Courses
        </a>
      </li>
      <li className="nav-drawer__top-bar-nav-item">
        <a
          className="graphite-p-s"
          href="https://www.monashcollege.edu.au/life-at-monash-college/our-campus"
          data-click-event="monash.navigation.link"
          data-type="link"
          data-section="global utility navigation"
          data-title="Campus"
        >
          Campus
        </a>
      </li>
    </ul>
  </div>
  <div className="nav-drawer__header">
    <div className="nav-drawer__controls">
      <button
        className="nav-drawer__control nav-drawer__back"
        data-nav-drawer="back"
      >
        <span className="sr-only">Previous menu</span>
        <svg className="icon nav-drawer__icon nav-drawer__icon--chevron">
          <use
            xmlnsXlink="/assets/images/icon-spritesheet.svg#chevron-left"
          ></use>
        </svg>
      </button>
      <button
        className="nav-drawer__control nav-drawer__close"
        data-nav-drawer="close"
      >
        <span className="sr-only">Toggle navigation</span>
        <svg className="icon nav-drawer__icon nav-drawer__icon--close">
          <use
            xmlnsXlink="/assets/images/icon-spritesheet.svg#close"
          ></use>
        </svg>
      </button>
    </div>
  </div>
  <div className="nav-drawer__body" data-nav-drawer="body">
    <div className="nav-drawer__wrapper" data-nav-drawer="wrapper">
      <ul className="nav-drawer__menu" data-nav-drawer="menu">
        <li
          className="nav-drawer__menu-item"
          data-nav-id="study-areas-642522"
          data-level="1"
        >
          <a
            className="
              nav-drawer__menu-link
              graphite-nav-link graphite-nav-link--bold
              nav-drawer__parent-link
            "
            href="https://www.monashcollege.edu.au/study-areas"
            data-click-event="monash.navigation.link"
            data-type="link"
            data-section="Header"
            data-title="Study areas"
          >
            Study areas
          </a>
        </li>
        <li
          className="nav-drawer__menu-item"
          data-nav-id="courses-642553"
          data-level="1"
        >
          <a
            className="
              nav-drawer__menu-link
              graphite-nav-link graphite-nav-link--bold
              nav-drawer__parent-link
            "
            href="https://www.monashcollege.edu.au/courses"
            data-click-event="monash.navigation.link"
            data-type="link"
            data-section="Header"
            data-title="Courses"
          >
            Courses
          </a>
        </li>
        <li
          className="nav-drawer__menu-item"
          data-nav-id="college-life-647241"
          data-level="1"
        >
          <a
            className="
              nav-drawer__menu-link
              graphite-nav-link graphite-nav-link--bold
              nav-drawer__parent-link
            "
            href="https://www.monashcollege.edu.au/life-at-monash-college"
            data-click-event="monash.navigation.link"
            data-type="link"
            data-section="Header"
            data-title="College Life"
          >
            College Life
          </a>
        </li>
        <li
          className="nav-drawer__menu-item"
          data-nav-id="how-to-apply-642557"
          data-level="1"
        >
          <a
            className="
              nav-drawer__menu-link
              graphite-nav-link graphite-nav-link--bold
              nav-drawer__parent-link
            "
            href="https://www.monashcollege.edu.au/how-to-apply"
            data-click-event="monash.navigation.link"
            data-type="link"
            data-section="Header"
            data-title="How to apply"
          >
            How to apply
          </a>
        </li>
        <li
          className="nav-drawer__menu-item"
          data-nav-id="information-for-642565"
          data-level="1"
        >
          <a
            className="
              nav-drawer__menu-link
              graphite-nav-link graphite-nav-link--bold
              nav-drawer__parent-link
            "
            href="https://www.monashcollege.edu.au/information-for"
            data-click-event="monash.navigation.link"
            data-type="link"
            data-section="Header"
            data-title="Information for"
          >
            Information for
          </a>
        </li>
        <li
          className="nav-drawer__menu-item"
          data-nav-id="current-students-1614140"
          data-level="1"
        >
          <a
            className="
              nav-drawer__menu-link
              graphite-nav-link graphite-nav-link--bold
              nav-drawer__parent-link
            "
            href="https://www.monashcollege.edu.au/current-students"
            data-click-event="monash.navigation.link"
            data-type="link"
            data-section="Header"
            data-title="Current students"
          >
            Current students
          </a>
        </li>
        <li
          className="nav-drawer__menu-item"
          data-nav-id="about-us-642570"
          data-level="1"
        >
          <a
            className="
              nav-drawer__menu-link
              graphite-nav-link graphite-nav-link--bold
              nav-drawer__parent-link
            "
            href="https://www.monashcollege.edu.au/about-us"
            data-click-event="monash.navigation.link"
            data-type="link"
            data-section="Header"
            data-title="About us"
          >
            About us
          </a>
        </li>
        <li
          className="nav-drawer__menu-item"
          data-nav-id="contact-us-642583"
          data-level="1"
        >
          <a
            className="
              nav-drawer__menu-link
              graphite-nav-link graphite-nav-link--bold
              nav-drawer__parent-link
            "
            href="https://www.monashcollege.edu.au/contact-us"
            data-click-event="monash.navigation.link"
            data-type="link"
            data-section="Header"
            data-title="Contact us"
          >
            Contact us
          </a>
        </li>
      </ul>
    </div>
  </div>
  <div className="nav-drawer__footer"></div>
</section>


        </React.Fragment>

    );
}

export default Header;