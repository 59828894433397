
export default function PanelBottom({ isOpen, onRequestClose, children }) {

    return <div className={`toppanel ${isOpen ? 'open' : ''}`}>

        {children}
        <div className="header">
            <button onClick={onRequestClose}>X</button>
        </div>
    </div>
}

