import React, { useEffect, useState, useContext, useRef } from "react"
import './App.css'
import { initMap, setControls } from './utils/maps'
import { MapContext } from './contexts/MapContext';
import mw from './utils/muMaps'
import apiMobileApp from "./api/apiMobileApp";


import PanelLeft from './components/Panels/PanelLeft'
import PanelBottom from './components/Panels/PanelBottom'
import PanelTop from './components/Panels/PanelTop'
import Directions from './components/Directions/Directions'
import Campus from './components/Campus/Campus'
import SearchControl from './components/SearchControl/SearchControl'
import PointOfInterestInfo from './components/PointOfInterestInfo/PointOfInterestInfo'


import twoFingerMapboxPan from './utils/two-finger-pan'


const initalPanelStates = {
  topPanel: {},
  bottomPanel: {
    css: ''
  }
}

function App() {
  const mapContainer = useRef()

  const [state, setState] = useState({
    isLeftPanelOpen: false,
    isBottomPanelOpen: false,
    isTopPanelOpen: false
  })

  const { map, dispatch } = useContext(MapContext);
  const [panelState, setPanelState] = useState(initalPanelStates)
  const [routeHtml, setRouteHtml] = useState(null)
  const [routeStepsHtml, setRouteStepsHtml] = useState([])
  const [mapClicked, setMapClicked] = useState(null)
  const [mapClickedEvent, setMapClickedEvent] = useState(null)
 
  useEffect(() => {
    window.apiMobileApp = apiMobileApp;
    // return () => {
    //   cleanup
    // }
  }, [])
  
  useEffect(() => {

    const map = initMap(mapContainer.current, function(loaded){
      dispatch({type: 'MAP_LOADED', payload: {
        mapLoaded: loaded,
        monashMap: map
      }})

      twoFingerMapboxPan(map)

      // setTimeout(() => {
      //     var doc = window.document;
      //     var docEl = doc.documentElement;
      //     var requestFullScreen = docEl.requestFullscreen || docEl.mozRequestFullScreen || docEl.webkitRequestFullScreen || docEl.msRequestFullscreen;
      //     var cancelFullScreen = doc.exitFullscreen || doc.mozCancelFullScreen || doc.webkitExitFullscreen || doc.msExitFullscreen;
      //     // if(!doc.fullscreenElement && !doc.mozFullScreenElement 
      //     //   && !doc.webkitFullscreenElement 
      //     //   && !doc.msFullscreenElement) {
      //       requestFullScreen.call(docEl);
      //       //cancelFullScreen.call(doc);
      //     //
      // }, 5000)

      // example of well lit path
      setTimeout(()=> mw.drawWellLitRoute(), 2000)

    })

    //setMonashMap(map)
    setControls(map, (latlng, z, poi, e) => {
      // map click
      console.log("map clicked:", e, poi, latlng)
      setMapClicked(poi ? poi : latlng)
      setMapClickedEvent(e)
      setState({ isBottomPanelOpen: true })

    })

  
    return () => map.remove() //cleanup
  }, [dispatch])


  const updateDirections = (dir = 'to') => {
    const data = {
      ...mapClicked
    }

    dispatch({
      type: 'UPDATE_DIRECTION', 
      payload: {
        [dir]: data
      }
    })

    mw.placeDirectionsMarker(dir, mapClickedEvent.lngLat )
  }

  const updateDirections2 = (data) => {
    //console.log("updateDirections2", data)
    dispatch({
      type: 'UPDATE_DIRECTION', 
      payload: data
    })

    if (mw.hasAllRoutingData(data)){
      setRoute(data)
    }
  }

  const updatePanelStates = (parent, key, val) => {

    setPanelState(prev => ({
      ...prev,
      [parent]: {
        ...[parent],
        [key]: val
      }
    }))

  }

  const setRoute = (data) => {
    //console.log("x",data, map.directions)
    if (typeof data === 'undefined' || data === null) {
        data = map.directions
    }

    var getRouteData = (data) => { //determine if latlng or poiId
      if (data?.properties?.poiId) {
        return {
          poiId: data.properties.poiId
        }
      } else {
        return {
          lngLat: data
        } 
      }
    }

    //var start1 = { lngLat: {lng: -78.50003778139148, lat: 38.03098667324329}, zLevel: 0};
    //var start2 = { poiId: 194915 };
    //var start = { poiId: data.from.properties.poiId }; //directions.from.properties.poiId , 1154832, 1000008139
    //var dest = { poiId:  data.to.properties.poiId } ; // directions.to.properties.poiId ,  1000029050, 1178059
    let start = getRouteData(data.from)
    let dest = getRouteData(data.to)


    const options = {
      mode:     data.mode,
      avoidStairs: data.avoidStairs,
    }
    mw.setRoute(start, dest, map.monashMap, options, 
      function (geojson) {
        const html = mw.printRouteData(geojson, data.mode)
        setRouteHtml(html)

        window.Mazemap.Data
          .getDirections(start, dest, options)
          .then(function(data){
              console.warn('@ getDirections', data);
              const li =[];
              const list = data.routes[0].legs[0].steps
              for (let index = 0; index < list.length; index++) {
                const element = list[index];
                li.push(element.instruction)                
              }
              setRouteStepsHtml(li)

          }).catch(e => {
            // 404 no step by step instruction support for locations
            // (see doc: https://api.mazemap.com/js/v2.0.53/docs/#api-reference)
            console.error("e",e) 
            setRouteStepsHtml([])

          }); 
      })

  

  }

  const updateBottomPanelState = () => {

    if (panelState.bottomPanel.css !== '') {
      setPanelState(initalPanelStates)
    } else {
      setState({ isBottomPanelOpen: false })
    }
    
  }

  const searchItemClicked = (item) => {
    console.log("searchItemClicked",item)
    mw.clearMarker(map.monashMap);
    mw.placePoiMarker(item)
  }
   

  return (
    <div className="App">

      <div ref={mapContainer} className={"mazemap"}></div>
      

      <div>
        <button style={{ position: "relative", zIndex: 10 }} onClick={() => setState({ isLeftPanelOpen: true })}>
          Click me to open right pane!
        </button>
      </div>

      <PanelTop
        isOpen={state.isBottomPanelOpen || false}
        onRequestClose={() => setState({ isBottomPanelOpen: false })}
      >
        <Directions 
          directions={map.directions} 
          goHandler={(e)=>setRoute(null)}
          backButtonHandler={() => setState({ isBottomPanelOpen: false })}
          updateDirectionHandler={updateDirections2}
          swapDirectionHandler={()=> dispatch({type: 'SWAP_DIRECTION'})} />
      </PanelTop>

      <PanelLeft
        isOpen={state.isLeftPanelOpen || false}
        onRequestClose={() => setState({ isLeftPanelOpen: false })}
      >
        <SearchControl hasMapLoaded={map.mapLoaded} itemClickHandler={(item) => searchItemClicked(item)} />
        <Campus onCloseRequest={() => setState({ isLeftPanelOpen: false })} />
      </PanelLeft>

    
      <PanelBottom
        isOpen={state.isBottomPanelOpen || false}
        onRequestClose={() => updateBottomPanelState()}
        css={panelState.bottomPanel.css || ''}
      >
        <div style={{textAlign: "center"}}>
          {mapClicked && (mapClicked.lat || mapClicked.properties) && (
            <div>
              <PointOfInterestInfo poi={mapClicked} />
              <div className="button-container">
                <button onClick={(e) => updateDirections('from')}>Start</button>
                <button onClick={(e) => updateDirections('to')}>Destination </button>
                <button onClick={(e) => updatePanelStates('bottomPanel', 'css', 'full')}>Details</button>
              </div>
            </div>
            
          )}
     
    
        </div>

        <ul id="routeData" dangerouslySetInnerHTML={{__html: routeHtml}} />
        {
          <ol id="routeInstructions">
            {routeStepsHtml.map(item => {
              return (
                <li key={Math.random()}>
                 {item}
                </li>
              )
            })}
          </ol>
        }
      </PanelBottom>

    </div>
  )
}

export default App
