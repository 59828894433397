import React, { useRef, useState, useContext } from "react"

import { MapContext } from './../../contexts/MapContext';
import mw from '../../utils/muMaps'
import s from './SearchControl.module.css'

export default  function SearchControl({hasMapLoaded = false, itemClickHandler}) {
    const refSearchInputContainer = useRef()
    const refSearchInput = useRef()
    const refSuggestions = useRef()
    // const { dispatch } = useContext(MapContext);
    const [currentRadioSelected, setCurrentRadioSelected] = useState("All")

    React.useEffect(() => {
        if (hasMapLoaded) {
            var mySearch = new window.Mazemap.Search.SearchController({
                // campusid: 159, // remove campus id to search all
                campuscollectiontag: "monashuni",
                rows: 10,
                withpois: true,
                withbuilding: false,
                withtype: false,
                withcampus: false,
                resultsFormat: 'geojson'
            });

            const x  = new window.Mazemap.Search.SearchInput({
                container: refSearchInputContainer.current,
                input: refSearchInput.current,
                suggestions: refSuggestions.current,
                searchController: mySearch
            });
            
            x.on('itemclick', function(e){
                itemClickHandler(e.item)
            })
            
            window.map.getCanvas().addEventListener('focus', function(){
                x.hideSuggestions();
            });

            window.map.muStore.searchInput = x
            window.map.muStore.searchController = mySearch
        }
    });
    
    const radioChangeHandler = (e, campusId) => {
        
        if (e.target.checked) {
            mw.updateSearchController(campusId)
            setCurrentRadioSelected(e.target.val || e.target.value)
        }
    }
    
    return ( hasMapLoaded && 
        <section className={s.searchWrapper2}>
            <div ref={refSearchInputContainer} className={s.searchControlDefault}>
                <input tabIndex="0" ref={refSearchInput}
                    className={"search-input"}
                    autoComplete="off" type="text"
                    name='search' placeholder='Search' maxLength={50} />

                <div ref={refSuggestions} className={`search-suggestions default ${s.ss}`}></div>
            </div>
            <div className={s.searchOptions}>

                <ul className={s.hz}>
                    {
                        //map.campus.id && <li><input type="radio" name="opt" id="opt1" value={map.campus.id.toString()} checked={currentRadioSelected == map.campus.id} onChange={(e) => radioChangeHandler(e, map.campus.id)} /><label htmlFor="opt1">{map.campus.name}</label></li>
                    }
                    {
                        Object.keys(mw.CAMPUS_DETAILS).map(function (key, index) {
                            let id = mw.CAMPUS_DETAILS[key].id
                            return (<li key={Math.random()}><input
                                id={`sc${id}`}
                                type="radio" name="opt"
                                value={id.toString()} 
                                checked={currentRadioSelected === id.toString()}
                                onChange={(e) => radioChangeHandler(e, id)}
                            />
                            <label htmlFor={`sc${id}`}>{mw.CAMPUS_DETAILS[key].name}</label></li>)
                        })
                    }
                    <li><input type="radio" name="opt" id="opt2" value={"All"} checked={currentRadioSelected === "All"} onChange={(e) => { radioChangeHandler(e, null) }} /><label htmlFor="opt2">All Campuses</label></li>
                </ul>

            </div>

        </section>
    )
}