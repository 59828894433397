

import mw from '../../utils/muMaps'
import s from './PointOfInterestInfo.module.css'
export default function PointOfInterestInfo({ poi }) {

    // poi can be just lng/lat point on map
    if (!(poi && (poi.properties || poi.lat))) {
        return
    }


    if (poi.properties){
        //poi.properties.identifier
        const campus = mw.getCampusById(poi.properties.campusId)
        return <div className={s.poiCard}>
            {poi.properties.title} {poi.properties.floorName && ` (Floor ${poi.properties.floorName})`}
            {poi.properties.types && poi.properties.types.length > 0 && <span className={s.type}>{poi.properties.types[0].name}</span>}
            <p>
                {poi.properties.buildingName && `${poi.properties.buildingName} - `}
                <strong>{campus.name} Campus</strong>
            </p>
        </div>
    } else {
        const campusNearestTo = mw.nearestToCampus(poi.lat, poi.lng)
        return <div className={s.poiCard}>
            <p>{poi.lng}, {poi.lat}</p>
            <strong>{campusNearestTo.name} Campus</strong>
        </div>
    }
    
    

}

