
export default function PanelLeft({ isOpen, onRequestClose, children }) {

    return <div className={`sidepanel ${isOpen ? 'open' : ''}`}>
        <div className="header">
            <button onClick={onRequestClose}>X</button>
        </div>
        {children} </div>
}

