
import mw from './muMaps'

const mapStyles = [
    "mapbox://styles/mapbox/streets-v11",
    "mapbox://styles/mapbox/outdoors-v11",
    "mapbox://styles/mapbox/light-v9",
    "mapbox://styles/mapbox/dark-v9",
    "mapbox://styles/mapbox/light-v10",
    "mapbox://styles/mapbox/dark-v10",
    "mapbox://styles/mapbox/satellite-v9",
    "mapbox://styles/mapbox/satellite-streets-v11",
    "mapbox://styles/mapbox/navigation-preview-day-v4",
    "mapbox://styles/mapbox/navigation-preview-night-v4",
    "mapbox://styles/mapbox/navigation-guidance-day-v4",
    "mapbox://styles/mapbox/navigation-guidance-night-v4"
]

export const initMap = (mapContainer, cb) => {

    var m = new window.Mazemap.Map({
        // container id specified in the HTML
        container: mapContainer,
        campuses: 'monashuni',
        // initial position in lngLat format
        center: { lng: 145.136514, lat: -37.910881 },
        //style: mapStyles[0], // style URL
        // initial zoom
        zoom: 18,
        zLevel: 1,
        zLevelControl: true,
        doubleClickZoom: true,
        touchZoomRotate: true,
        dragPan: true
    });

    m.muStore = {}
    window.map = m;
    

    window.map.on('load', (e) => {
        console.log('mazemap ready...',e.target)
        const map = e.target || window.map

        // Initialize a Highlighter for POIs
        // Storing the object on the map just makes it easy to access for other things
        map.muStore.highlighter = new window.Mazemap.Highlighter(map, {
            showOutline: true,
            showFill: true,
            outlineColor: window.Mazemap.Util.Colors.MazeColors.MazeBlue,
            fillColor: window.Mazemap.Util.Colors.MazeColors.MazeBlue
        })

        map.muStore.routeController = new window.Mazemap.RouteController(map);
        
        cb(true)

        
        // setTimeout(()=>{
        //     map.setStyle(mapStyles[3]);
        // },5000)

        LocationController(map)
        //setLocationControllers(map)
        //setBlueDot(map)
    })

    
    return m;

}

export function setControls(map, cb) {

        // Add zoom and rotation controls to the map.
        //map.addControl(new window.Mazemap.mapboxgl.NavigationControl());

        map.on('click', function(e){
            
            //map.muStore.routeController.clear(); // Clear existing route, if any

            const lngLat = e.lngLat
            if (lngLat.lng && lngLat.lat) {
                window.Mazemap.Data.getPoiAt(lngLat, window.map.zLevel).then(poi => {
                    mw.clearMarker(map);
                    if (poi) {
                        mw.placePoiMarker(poi);
                        cb(lngLat, map.zLevel, poi, e)
                    } else {
                        mw.placeMarker(lngLat)
                        cb(lngLat, map.zLevel, null, e)
                    }

                }).catch(() => { return false })
            } else {
                // error
            }
        });


}


function LocationController (map) {

        // https://docs.mapbox.com/mapbox-gl-js/api/markers/#geolocatecontrol.event:error
    var geolocateControl = new window.Mazemap.mapboxgl.GeolocateControl({
        positionOptions: {
            enableHighAccuracy: true,
            timeout: 10000
        },
        // When active the map will receive updates to the device's location as it changes.
        trackUserLocation: true,
        showUserLocation: true,
        showAccuracyCircle: true,
        // Draw an arrow next to the location dot to indicate which direction the device is heading.
        showUserHeading: true    
    })
    
    map.muStore.geolocateControl = geolocateControl;
    map.addControl(new window.Mazemap.mapboxgl.FullscreenControl());
    map.addControl(new window.Mazemap.mapboxgl.NavigationControl(), 'bottom-right');
    map.addControl(geolocateControl, 'bottom-right');
    map.on('load', function () {

        // https://docs.mapbox.com/mapbox-gl-js/api/markers/#geolocatecontrol.event:error
        geolocateControl.on('geolocate', function (data) {
            console.log('A geolocate event has occurred.', geolocateControl, data)

            // // first time the user grants permission
            // if (!firstLocationData.hasLocation && firstLocationData.lngLat) {
            //     firstLocationData.hasLocation = true;
            //     if (isUserLocationActive()) {
            //         setTimeout(function(){
            //             setRoute(firstLocationData.lngLat)
            //         },1000)
            //     }
            // }

        });
        geolocateControl.on('error', function (err) {
            console.log('An error event has occurred.')

            // // when an error (on first denial of device location)
            // if (!firstLocationData.hasLocation && firstLocationData.lngLat) {
            //     if (geolocateControl) {
            //         var b = geolocateControl._geolocateButton;
            //         if (b && b.disabled) {
            //             alert("Please allow device location permissions.");
            //             return;
            //         }
            //     }
            // }

        });
        geolocateControl.on('trackuserlocationstart', () => {
            console.log('A trackuserlocationstart event has occurred.');
            });
        // when a trackuserlocationend event occurs.
        geolocateControl.on('trackuserlocationend', () => {
            console.log('A trackuserlocationend event has occurred.');
        });        
        geolocateControl.on('outofmaxbounds', () => {
            console.log('An outofmaxbounds event has occurred.');
        });

        geolocateControl.trigger();

    }); // end map load

}


function setLocationControllers(map) {


    // ****************************************************************************************
    // location 
    var MAZEMAP_LIST_LIPI_URL = 'https://siteresolver.mazemap.com/siteresolver/';

    var updateBlueDotLocation = function(currentLocation) {

        if (!currentLocation)
            return;
        var latLng = currentLocation.latLng; 
        var z=currentLocation.z;
        var	zoomLevel = Math.log2(591657550.5 / (currentLocation.accuracy * 45))-1;
        var accuracy = currentLocation.accuracy;
        geolocateController.setState('active');
        if (!window.map.muStore.blueDot?._addedToMap) {
            window.map.muStore.blueDot.setLngLat(latLng).setZLevel(z).setAccuracy(accuracy)//.addTo(map);
            map.zLevel=z;
            map.flyTo({
                center: latLng,
                speed: 1,
                zoom: zoomLevel
            });
        }
        locationDotController.updateLocationData({
            lngLat: latLng,
            zLevel:z,
            accuracy:accuracy
        });
    }

    var UOMWirelessGeolocation = function() {
        return new Promise(function(resolve, reject) {
            fetch(MAZEMAP_LIST_LIPI_URL).then(function(response) {
                if (!response.ok)
                    throw new Error("LIPI List Server - Communicate Error");
                return response.json();
            }).then(function(json) {
                if (json && json.error)
                    throw new Error("LIPI List Server Error: "+json.error);
                if (json){
                    var LIPI_URL = json.positionUrl;
                    fetch(LIPI_URL).then(function(response) {
                        if (!response.ok)
                            throw new Error("LIPI Server - Communication Error ("+LIPI_URL+")");

                            console.debug("x", response)
                        return response.json();
                    }).then(function(json) {
                        console.debug("x", json)
                        if (json && json.error)
                            throw new Error("LIPI Server Error: "+json.error);
                        if (json){
                            if (typeof json.geoLongitude === 'undefined' || typeof json.geoLatitude ==='undefined')
                                throw new Error("No Geolocation data");
                             var result = {
                                latLng: {
                                    lat: Number(json.geoLatitude),
                                    lng: Number(json.geoLongitude)
                                },
                                accuracy: json.confidencefactor,
                                z: isNaN(json.z)?0:Number(json.z),
                                timestamp: Date.now()
                            };
                            console.debug("x", result)
                            return resolve(result);
                        }
                    }).catch(function(e) {
                        reject(e);
                    });							
                }	
            }).catch(function(e) {
                reject(e);
            });
        });
    }
    
    
    var UOMGeolocation = function(){
        if (navigator.geolocation)
            geoLocationWatchID = navigator.geolocation.watchPosition(
                function(data){
                    UOMWirelessGeolocation()
                    .then(function(UOMLocation){
                        var UOMGeolocationEvent = new CustomEvent("UOMGeolocate", {
                            "detail": UOMLocation
                        });
                        window.dispatchEvent(UOMGeolocationEvent);	
                        updateBlueDotLocation(UOMLocation);
                    })
                    .catch(function(){
                        var result = {
                            latLng: {
                                lat: Number(data.coords.latitude),
                                lng: Number(data.coords.longitude)
                            },
                            accuracy: data.coords.accuracy,
                            z: 0,
                            timestamp: data.timestamp
                        };
                        var UOMGeolocationEvent = new CustomEvent("UOMGeolocate", {
                            "detail": result
                        });
                        window.dispatchEvent(UOMGeolocationEvent);	
                        updateBlueDotLocation(result);
                    });
                },
                function(error){
                    alert(`Error: `+ error.message)
                    geolocateController.setState('error');
                    locationDotController.setState('deactivated');
                    //if (window.map.muStore.blueDot) {window.map.muStore.blueDot.remove();}
                    navigator.geolocation.clearWatch(geoLocationWatchID);
                    console.log(error);
                }
            );
    }
    

    
    // Windows onLoad
    window.onload = function() {
        // code for direct link
        if (document.getElementById("getDirectionLnk"))
            window.addEventListener("UOMGeolocate", function(evt) {
                console.info('Event call');
                var href = new URL(document.getElementById("getDirectionLnk").href);
                if (href) {
                    href.searchParams.set('lngA', evt.detail.latLng.lng);
                    href.searchParams.set('latA', evt.detail.latLng.lat);
                    href.searchParams.set('zA', evt.detail.z);
                    document.getElementById("getDirectionLnk").href= href.toString();
                }
            }, false);
    };
    
    window.onunload = function() {
        navigator.geolocation.clearWatch(geoLocationWatchID);
    };        
    

    // ************************************************************************



    //https://api.mazemap.com/js/v2.0.53/docs/#ex-bluedot-location-controller

    // First make a BlueDot and add to the map
    var blueDot = new window.Mazemap.BlueDot({
        map: map,
    })
    .setZLevel(1)
    .setLngLat({ lng: 145.136514, lat: -37.910881 })
    .setAccuracy(1)
    .show();

    var geoLocationWatchID = null;
    var geolocateController = new window.Mazemap.GUIComponents.LocationControl( { 
        positionOptions: {
            enableHighAccuracy: true
        },
        trackUserLocation: true
    });
    
    geolocateController.on('click', function(){
        // //demos the state if uncomment
        // var state = locationDotController.state;
        // if(state === 'deactivated'){
        //     locationDotController.setState('active');
        //     return;
        // }
        // if(state === 'active'){
        //     locationDotController.setState('follow');
        //     return;
        // }
        // if(state === 'follow'){
        //     locationDotController.setState('passive');
        //     return;
        // }    
        // if(state === 'passive'){
        //     locationDotController.setState('deactivated');
        //     return;
        // }
        // return;

        switch(locationDotController.state) {
            case 'deactivated':
                geolocateController.setState('working');
                locationDotController.setState('active');
                break;
            case 'active':
                //locationDotController.setState('follow');
                navigator.geolocation.clearWatch(geoLocationWatchID);
                //blueDot.remove(); 
                //if (window.map.muStore.blueDot) {window.map.muStore.blueDot.remove();}
                locationDotController.setState('deactivated');
                break;
            case 'follow':
                //locationDotController.setState('deactivated');
                break;
            default:

        }
        
           

    });    

    map.addControl( geolocateController, 'top-right' );

    var locationDotController = locationDotController = new window.Mazemap.LocationController({
        blueDot: blueDot,
        map: map
    });


    locationDotController.on('statechange', function(){
        console.log("statechange", locationDotController.state)

        // //demos the state if uncomment
        // var state = locationDotController.state;
        // if(state === 'follow'){
        //     geolocateController.setState('follow');
        // }else if(state === 'active'){
        //     geolocateController.setState('active');
        // }else if(state === 'deactivated'){
        //     geolocateController.setState('off');
        // } else {
        //     geolocateController.setState('default');
        // }
        // return;

        switch (locationDotController.state) {
            case 'follow':
                geolocateController.setState('active');
                break;
            case 'active':
                //geolocateController.setState('default');
                UOMGeolocation();
                break;
            case 'deactivated':
                if (geolocateController.getState !== 'error') {
                    geolocateController.setState('passive') }
                else {
                    geolocateController.setState('off')
                }
                break;
            default:
        }

    });

    // Default (comment below lines to only active when users press the button)
    // geolocateController.setState('passive');
    // locationDotController.setState('active');

    map.muStore.geolocateController = geolocateController //locationGUIControl
    map.muStore.locationDotController = locationDotController
    map.muStore.blueDot = blueDot


    
}


function setBlueDot(map){
    // on the mazemap api doc
    
    var blueDot = new window.Mazemap.BlueDot( {
        map: map,
    } )
    .setZLevel(4)
    .setLngLat({ lng: 145.136514, lat: -37.910881 })
    .setAccuracy(10)
    .show();



    var locationGUIControl = new window.Mazemap.GUIComponents.LocationControl( {} );
    map.addControl( locationGUIControl, 'top-right' );

    locationGUIControl.on('click', function(){
        // Toggle the state
        var state = locationController.state;
        if(state === 'deactivated'){
            locationController.setState('active');
            return;
        }
        if(state === 'active'){
            locationController.setState('follow');
            return;
        }
        if(state === 'follow'){
            locationController.setState('deactivated');
            return;
        }
    });

    var locationController = new window.Mazemap.LocationController({
        blueDot: blueDot,
        map: map
    });

    locationController.on('statechange', function(){
        var state = locationController.state;
        if(state === 'follow'){
            locationGUIControl.setState('active');
        }else if(state === 'active'){
            locationGUIControl.setState('default');
        }else if(state === 'deactivated'){
            locationGUIControl.setState('off');
        }
    });

    // Default activated
    locationGUIControl.setState('passive');
    locationController.setState('active');


}

