
/*
    expects a list of poiIds [111,111]
    example: apiMobileApp.snapToPOI([1163363,1160933,1230298])
*/
const snapToPOI = (poiIds) => {


    const allPromises = poiIds.map((poi) => {
        return new Promise((resolve, reject) => {
            window.Mazemap.Data.getPoi(poi).then(info => {
                resolve(info)
            }).catch((e) => reject(new Error(e.message)));
        });
    })

    Promise.all(allPromises).then(pois => {


        //Mazemap.Data.getPoi(1163363).then( poi => {...} )


        var poi;
        window.map.muStore.markers = window.map.muStore.markers || [];
        for (var i = 0; i < pois.length; i++) {
            poi = pois[i];

            // Get a center point for the POI, because the data can return a polygon instead of just a point sometimes
            const lngLat = window.Mazemap.Util.getPoiLngLat(poi);

            let marker = new window.Mazemap.MazeMarker({
                color: 'rgb(0, 105, 206)',
                innerCircle: true,
                innerCircleColor: '#FFF',
                size: 30,
                shape: 'circle',
                innerCircleScale: 0.4,
                zLevel: poi.properties.zLevel
            })
                .setLngLat(lngLat)
                .addTo(window.map);

            window.map.muStore.markers.push(marker)
        }


        // if(pois.length > 0) {
        //     var bbox = window.Mazemap.Util.Turf.bbox(pois);
        //     var bounds = new window.Mazemap.mapboxgl.LngLatBounds(bbox);
        //     window.map.fitBoundsRotated(bounds, {padding: 100});
        // }

        if (pois.length > 0) {
            // Zoom to bounds of all markers
            var bounds = new window.Mazemap.mapboxgl.LngLatBounds();
            window.map.muStore.markers.forEach((marker) => {
                bounds.extend(marker.getLngLat());
            });
            window.map.fitBounds(bounds, { padding: 100 });


        }





    })
        .catch((e) => {
            alert(e.message)
        })






}



const clearMarkers = () => {
    const monashMap = window.map
    if (monashMap.muStore && monashMap.muStore.markers) {
        for (var i = 0; i < monashMap.muStore.markers.length; i++) {
            monashMap.muStore.markers[i].remove();
        }
    }
    if (monashMap && monashMap.muStore && monashMap.muStore.highlighter) {
        monashMap.muStore.highlighter.clear();
    }
}



const apiMobileApp = {
    snapToPOI,
    clearMarkers
}


//https://confluence.apps.monash.edu/pages/viewpage.action?spaceKey=MMA&title=Mobile+API+requirements
export default apiMobileApp


