import React, { useState, useContext } from "react"
import mw from '../../utils/muMaps'
import { MapContext } from './../../contexts/MapContext';


export default function Campus({onCloseRequest, displayCampusPOI = true}) {
    //const [campusSelected, setCampusSelected] = useState(null) //string key
    const [campusCagetoryData, setCampusCagetoryData] = useState({})
    const { map, dispatch } = useContext(MapContext);
    const campusDetails = mw.CAMPUS_DETAILS
    const campusSelected = map.campus.key;
    
    const campusChangeHandler = (e) => {
        const campusKey = e.target.val || e.target.value

        if (campusKey && campusKey !== '' ) {
            const campusId = campusDetails[campusKey].id
            const name = campusDetails[campusKey].name
            dispatch({ 
                type: 'UPDATE_CAMPUS', 
                payload: { campusKey: campusKey, id: campusId, name } 
            })

            mw.switchCampus(campusKey)
            if (!campusCagetoryData.hasOwnProperty(campusKey)){
                mw.getCampusCategories(campusId)
                .then(data => {
           
                    setCampusCagetoryData(prev => ({
                        ...prev,
                        [campusKey]: data
                    }))
                    console.log("data",data)
                })
            }
           
        } else {
            console.error(`Campus key not found: ${campusKey}`)
        }
        
    
    }

    const placeCategoryMarkers = (poiTypeId) => {
        const campusId = campusDetails[campusSelected].id
        window.Mazemap.Data.getPoisOfTypeAsGeoJSON({ poiTypeId, campusId })
            .then(pois => {
                mw.clearCategoryMarkers(window.map);
                mw.placePoiCategoryMarkers(pois);
                
            }).finally(onCloseRequest);

    }

    return <div>
        <select  onChange={campusChangeHandler} value={campusSelected|| ''}>
            <option value="" disabled>Please select</option>
            {
                Object.keys(campusDetails).map(function (key, value) {
                    return <option 
                        key={Math.random()} 
                        value={key}>
                            {campusDetails[key].name}
                    </option>
                })
            }
        </select>      
        {displayCampusPOI && 
            <div id="campusCategories">
                <ol>
                    {
                        campusSelected &&
                        campusCagetoryData && 
                        campusCagetoryData[campusSelected] && 
                        campusCagetoryData[campusSelected]?.map((el, index) => {
                            return <li key={index} onClick={(e) => placeCategoryMarkers(el.id)}>{el.title}</li>;
                        })
                    }
                </ol>
            </div>
        }
    </div>

}
